<template>
  <div class="row justify-content-center px-8 px-lg-10">
    <div class="col-xl-12 col-xxl-7 justify-content-center">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <!--begin::Form Wizard-->
        <form class="form" @submit.prevent="handleSubmit(_handleSubmit)">
          <!--begin::Step 1-->
          <div data-wizard-type="step-content" data-wizard-state="current">
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('payment_methods.currency_no') }}</label>
                  <validation-provider rules="required|numeric" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <input
                      class="form-control"
                      :name="$t('payment_methods.currency_no')"
                      v-model="formData.currency_id"
                      :class="classes"
                      type="number"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('payment_methods.method_name') }}</label>
                  <validation-provider rules="required" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <input
                      class="form-control"
                      :name="$t('payment_methods.method_name')"
                      type="text"
                      v-model="formData.name"
                      :class="classes"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('payment_methods.fee') }}</label>
                  <validation-provider rules="required|double" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <input
                      class="form-control"
                      :name="$t('payment_methods.fee')"
                      v-model="formData.fee"
                      :class="classes"
                      type="text"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('payment_methods.fee_type') }}</label>
                  <validation-provider
                    rules="required" :name="$t('payment_methods.fee_type')" 
                    v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading"
                  >
                    <multiselect
                      v-model="formData.fee_type"
                      :options="fee_types"
                      :searchable="false"
                      :allow-empty="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      open-direction="bottom"
                      :placeholder="$t('payment_methods.fee_type')"
                      :class="classes"
                    >
                      <template slot='singleLabel' slot-scope='{ option }'>{{ option | uppercase }}</template>
                      <template slot='option' slot-scope='{ option }'>{{ option | uppercase }}</template>
                      <template slot='noOptions'>{{ 'List is empty' }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('payment_methods.min_unit') }}</label>
                  <validation-provider rules="required|double" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <input
                      class="form-control"
                      :name="$t('payment_methods.min_unit')"
                      v-model="formData.min_pay"
                      :class="classes"
                      type="text"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('payment_methods.max_unit') }}</label>
                  <validation-provider :rules="`required|double|min_value:${formData.min_pay}`" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <input
                      class="form-control"
                      :name="$t('payment_methods.max_unit')"
                      v-model="formData.max_pay"
                      :class="classes"
                      type="text"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('payment_methods.bank_name') }}</label>
                  <validation-provider
                    rules="required" :name="$t('payment_methods.bank_name')" 
                    v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading"
                  >
                    <multiselect
                      v-model="selectedBank"
                      :options="banks"
                      :allow-empty="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      open-direction="bottom"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      track-by='short'
                      label='short'
                      :placeholder="$t('payment_methods.bank_name')"
                      :class="classes"
                    >
                      <template slot='singleLabel' slot-scope='{ option }'>{{ option.short }}</template>
                      <template slot='option' slot-scope='{ option }'>{{ option.short }}</template>
                      <template slot='noOptions'>{{ 'List is empty' }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('payment_methods.bank_address') }}</label>
                  <validation-provider rules="required" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <input
                      class="form-control"
                      :name="$t('payment_methods.bank_address')"
                      type="text"
                      v-model="formData.address"
                      :class="classes"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('payment_methods.status') }}</label>
                  <validation-provider
                    rules="required" :name="$t('payment_methods.status')" 
                    v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading"
                  >
                    <multiselect
                      v-model="selectedStatus"
                      :options="allStatus"
                      :searchable="false"
                      :allow-empty="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      open-direction="bottom"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      track-by='name'
                      label='name'
                      :placeholder="$t('payment_methods.status')"
                      :class="classes"
                    >
                      <template slot='singleLabel' slot-scope='{ option }'>{{ option.name }}</template>
                      <template slot='option' slot-scope='{ option }'>{{ option.name }}</template>
                      <template slot='noOptions'>{{ 'List is empty' }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!--begin::Actions-->
          <div class="d-flex justify-content-end border-top mt-5 pt-10">
            <button
              type="submit"
              class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
              :disabled="isSubmitting"
            >
              {{ $t('commons.submit') }}
            </button>
          </div>
          <!--end::Actions-->
        </form>
        <!--end::Form Wizard-->
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Skeleton } from 'vue-loading-skeleton';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import i18n from '@/config/_i18n'

const all_status = [
  { value: 10, name: i18n.t("institutions.active_status") },
  { value: 20, name: i18n.t("institutions.passive_status") }
]

export default {
  name: "Create",
  data() {
    return {
      formData: {
        type: '',
        currency_id: "",
        name: "",
        fee: "",
        fee_type: "",
        min_pay: "",
        max_pay: "",
        bank: "",
        address: "",
        status: ""
      },
      allStatus: all_status,
      selectedBank: null,
      selectedStatus: null
    };
  },
  components: {
    Skeleton,
    Multiselect
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.paymentMethodsManagemet.isLoading,
      isSubmitting: (state) => state.paymentMethodsManagemet.isSubmitting,
      banks: (state) => state.paymentMethodsManagemet.banks,
      fee_types: (state) => state.paymentMethodsManagemet.fee_type,
    }),
  },
  methods: {
    ...mapActions('paymentMethodsManagemet', ['GET_STORE_DATA', 'STORE']),
    _handleSubmit() {
        this.formData.bank = this.selectedBank.short;
        this.formData.status = this.selectedStatus.value/10;
        this.STORE(this.formData);
    }
  },
  created() {
    const { type } = this.$route.params;
    if(!['deposit', 'withdraw'].includes(type)) {
        this.$router.push("/payment-methods-management");
        return;
    }
    this.formData.type = type;
    this.GET_STORE_DATA(type);
    this.formData.status = all_status[0].value
  },
};
</script>
